import React, { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation, Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
// layouts
//import MainLayout from '../layouts/main';
// import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// components
import LoadingScreen from '../components/LoadingScreen';
import Loadable from '../components/Loadable';

// ----------------------------------------------------------------------

// const Loadable = (Component) => (props) => {
//   // eslint-disable-next-line react-hooks/rules-of-hooks
//   const { pathname } = useLocation();
//   // const isDashboard = pathname.includes('/dashboard');
//
//   return (
//     <Suspense
//       fallback={
//         <LoadingScreen
//           sx={{
//             // ...(!isDashboard && {
//             top: 0,
//             left: 0,
//             width: 1,
//             height: 1,
//             zIndex: 9999,
//             position: 'fixed'
//             // })
//           }}
//         />
//       }
//     >
//       <Component {...props} />
//     </Suspense>
//   );
// };

export default function Router() {
  return useRoutes([
    // Dashboard Routes
    // {
    //   path: 'dashboard',
    //   element: <DashboardLayout />,
    //   children: [
    //     { element: <Navigate to="/dashboard/one" /> },
    //     { path: 'one', element: <PageOne /> },
    //     { path: 'two', element: <PageTwo /> },
    //     { path: 'three', element: <PageThree /> },
    //     {
    //       path: 'app',
    //       children: [
    //         { element: <Navigate to="/dashboard/app/four" replace /> },
    //         { path: 'four', element: <PageFour /> },
    //         { path: 'five', element: <PageFive /> },
    //         { path: 'six', element: <PageSix /> }
    //       ]
    //     }
    //   ]
    // },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        {
          path: '',
          element: <HomePage />,
          children: [
            {
              path: '',
              element: <CustomerDetailsForm />
            },
            { path: '/confirm-details/:transactionReference', element: <ConfirmCustomerDetailsSection /> }
          ]
        },
        { path: '/make-payment/:ref/:channel', element: <MakePaymentPage /> }
      ]
    },
    {
      path: '/confirm-payment/:channel',
      element: <MainLayout />,
      children: [{ element: <ConfirmPaymentPage /> }]
    },
    {
      path: '/payment-complete/:reference',
      element: <MainLayout />,
      children: [{ element: <PaymentCompletePage /> }]
    },
    {
      path: '/payment-receipt',
      element: <MainLayout />,
      children: [{ element: <PaymentReceiptPage /> }]
    },
    /*{
      path: '/faq',
      element: <MainLayout />,
      children: [{ element: <FAQ /> }]
    },*/
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// IMPORT COMPONENTS

// Dashboard
// const PageOne = Loadable(lazy(() => import('../pages/PageOne')));
// const PageTwo = Loadable(lazy(() => import('../pages/PageTwo')));
// const PageThree = Loadable(lazy(() => import('../pages/PageThree')));
// const PageFour = Loadable(lazy(() => import('../pages/PageFour')));
// const PageFive = Loadable(lazy(() => import('../pages/PageFive')));
// const PageSix = Loadable(lazy(() => import('../pages/PageSix')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
// Main
const HomePage = Loadable(lazy(() => import('../pages/HomePage')));
const FAQ = Loadable(lazy(() => import('../pages/FAQ')));
const ConfirmCustomerDetailsSection = Loadable(
  lazy(() => import('../components/purchase-token/confirm_customer_details/ConfirmCustomerDetailsSection'))
);
const MakePaymentPage = Loadable(lazy(() => import('../components/purchase-token/make_payment/MakePaymentPage')));
const CustomerDetailsForm = Loadable(
  lazy(() => import('../components/purchase-token/customer_details/CustomerDetailsForm'))
);
const ConfirmPaymentPage = Loadable(lazy(() => import('../components/confirm_payment/ConfirmPaymentPage')));
const MainLayout = Loadable(lazy(() => import('../layouts/main')));
const PaymentReceiptPage = Loadable(lazy(() => import('../components/payment_receipt/PaymentReceiptPage')));
const PaymentCompletePage = Loadable(lazy(() => import('../components/payment_complete/PaymentCompletePage')));
